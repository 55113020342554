body {
  margin: 0;
}

button:focus {
  outline: none;
}

@font-face {
  font-family: 'Avenir Next';
  src: url(./fonts/AvenirNextLTPro-Regular.otf) format('opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Avenir Next';
  src: url(./fonts/AvenirNextLTPro-Bold.otf) format('opentype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir Next';
  src: url(./fonts/AvenirNextLTPro-It.otf) format('opentype');
  font-style: italic;
  font-weight: normal;
}
