.kp-chat-theme {
  height: 100% !important;
  input[type='file'] {
    display: none;
  }
  .str-chat__date-separator-date {
    font-family: 'Avenir Next';
  }
  .str-chat__emojisearch__list {
    font-family: 'Avenir Next';
    color: $text-inky;
  }
  .str-chat__container {
    background-color: $pebble-color;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
  }
  .str-chat__emojiselect-wrapper,
  .str-chat__fileupload-wrapper {
    display: none;
  }
  .str-chat__input--textarea-wrapper {
    textarea {
      font-family: 'Avenir Next';
      font-size: 16px;
      color: $text-dolphin;
      border: none;
      padding-right: 0 !important;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .str-chat__input {
    box-shadow: none;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .str-chat__input-footer {
    display: none;
  }
}
