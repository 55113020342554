/* Custom variables */

/* colors */

$pebble-color: #f7f7f7;

$white: #ffffff;

$mid-blue: #0078b3;

$text-inky: #0d1c3d;

$text-dolphin: #677083;
